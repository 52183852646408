export default () => ({
  name: "",
  webhookUrl: "",
  platfoform: "",
  tgApi: "",
  fanpageName: "",
  fanpageUrl: "",
  fanpageId: "",
  fbPageToken: "",
  fbVerifyToken: "",
  fbAppSecret: "",
  googleProjectId: "",
  dialogflowLanguageCode: "",
  googleClientEmail: "",
  googlePrivateKey: "",
  autoActivateAfter: 0,
});
